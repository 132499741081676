import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const UserLoginContext = createContext({});

export const UserLoginProvider = ({ children }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const value = useMemo(
    () => ({
      isUserLoggedIn,
      setIsUserLoggedIn,
    }),
    [isUserLoggedIn],
  );

  return (
    <UserLoginContext.Provider value={value}>
      {children}
    </UserLoginContext.Provider>
  );
};

UserLoginProvider.propTypes = {
  children: PropTypes.node,
};
