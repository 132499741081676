import { useContext, useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { SettingsContext } from 'contextAPI/settings';
import { isEmpty } from 'utils/helper';
import { useLocation, useNavigate } from 'react-router';
import { useFirebaseSignIn } from './SignInButton.react';
import { IDLE_TIMER } from 'utils/config';
import { pathName } from 'Routes/routes.const';

/**
 * Interceptor component for attaching the axios interceptor
 */
export const RequestInterceptor = ({ children }) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const settings = useContext(SettingsContext);
  const navigation = useNavigate();
  const { handleLogout, getLastActiveTime, setIdleModal } = useFirebaseSignIn();
  const date = new Date();
  const location = useLocation();

  useEffect(() => {
    const csrfHeader = settings?.csrf?.headerName ?? 'X-CSRF-TOKEN';
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common[csrfHeader] = settings?.csrf?.token ?? '';
  }, []);

  useEffect(() => {
    attachAxiosInterceptors();
  }, [currentUser]);

  const handleUserIdle = () => {
    if (location?.pathname === pathName.logout) {
      navigation(pathName.portfolio, { replace: true });
    }
    const timeDifference = Math.abs(
      (date.getTime() - getLastActiveTime()?.getTime()) / 60000,
    );
    /* istanbul ignore else */
    if (timeDifference >= IDLE_TIMER.IDLE_TIME_OUT) {
      handleLogout();
    } else if (
      timeDifference >=
      IDLE_TIMER.IDLE_TIME_OUT - IDLE_TIMER.WARNING_TIME_OUT
    ) {
      setIdleModal(true);
    }
  };

  function attachAxiosInterceptors() {
    axios.interceptors.request.use(requestInterceptor, (error) =>
      Promise.reject(error),
    );
    axios.interceptors.response.use(
      responseInterceptor,
      responseErrorInterceptor,
    );
  }

  async function requestInterceptor(config) {
    if (!isEmpty(currentUser) && settings.isDevEnvironment) {
      await attachFirebaseTokens(config);
    }
    return config;
  }

  function responseInterceptor(response) {
    if (!isEmpty(currentUser)) {
      handleUserIdle();
    }
    return response;
  }

  async function responseErrorInterceptor(error) {
    const status = error?.response?.status;
    const originalRequest = error.config;
    const currentPath = location?.pathname;

    if (status === 498 && window.location.pathname != '/logout') {
      handleLogout();
    } else if (
      status === 403 &&
      (isEmpty(currentUser) || currentPath === '/logout')
    ) {
      return;
    } else if (status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      await currentUser?.getIdToken().then((token) => {
        axios.defaults.headers.common['Authorization'] = token;
      });
      return axios(originalRequest);
    } else if ([401, 403, 404, 502, 503, 504].includes(status)) {
      navigation(`/error/${status}`, { replace: true });
    }

    return Promise.reject(error);
  }

  /**
   * This function takes care of attaching the access token for every request in the
   * authorization header. This also internally takes care of refreshing the tokens
   * when expired using refresh_token.
   * This would only redirect to login screen when the refresh_token is slao expired
   * @param { axios config} config
   */
  async function attachFirebaseTokens(config) {
    await currentUser.getIdToken().then((token) => {
      config.headers['Authorization'] = `${token}`;
    });
  }
  return children;
};
