import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { getAuth } from 'firebase/auth';
import { toast } from 'react-toastify';
import { STATUS_API_TIMEOUT } from 'utils/config';

export const ORIGINATION_LIST_CONTENT = {
  pageTitle: 'Projects',
  location: 'Region',
  origination_project_name: 'Name',
  origination_project_type: 'Type',
  origination_project_status: 'Status',
  origination_project_participants: 'Participants',
  origination_project_created_on: 'Last update',
  noDataContent: ' Looks like you haven’t added any projects yet.',
  addNewProjectLabel: 'Add a new project to get started!',
  addButton: 'Add project',
  tooltip:
    'Metric tons (MT) of carbon dioxide equivalents (CO2e) represents net greenhouse gas (GHG) reductions and removals to date.',
  asc: 'ASC',
  desc: 'DESC',
  participantFlag: 'participantFlag',
  co2emtAbatementFlag: 'co2emtAbatementFlag',
  createdOnFlag: 'createdOnFlag',
  right: 'right',
  small: 'small',
  delete_message:
    'Are you sure you want to delete and all data associated with it?',
  success: [200, 201, 204],
  success_message: 'was successfully deleted with all data associated with it.',
  error_message:
    'couldn’t be deleted due to a technical error. Please try again.',
  failed: 'FAILED',
  failed_message:
    'couldn’t be deleted due to a technical error. Please, try again.',
  deniend_message: 'Invalid project Id',
  clearAll: 'Clear all',
  searchboxPlaceholder: 'Search by project name',
  filter_content: 'We couldn’t find any results for the project',
  search_text_suffix: 'No results found',
  please_try_again: 'Please try again.',
  filter_content_width: '75%',
  last_updated: 'Last update',
  commercial_purpose: 'Commercial purpose',
  tooltip_text: 'Managed by ClearCarbon Origination',
  no_data_heading_width: '37.65%',
};

export const fields = {
  projectName: 'projectName',
  commercializationApproach: 'commercializationApproach',
  updatedDate: 'updatedDate',
};

export const SortColValues = {
  participants: 'Participants',
  co2emtAbatement: 'co2emtAbatement',
  createdOn: 'Date',
  updatedDate: 'updatedDate',
};
export const SortColNames = {
  participants: 'participantCount.participantCount',
  co2emtAbatement: 'co2emtAbatement',
  createdOn: 'createdDate',
  updatedDate: 'updatedDate',
};

export const LOCATIONS_FILTER = ['Northeast', 'Midwest', 'West', 'South'];

export const displayToast = (type, message) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const showToast = () => {
    toast(<CustomSnackbar type={type} message={message} />, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  if (currentUser) {
    showToast();
  } else {
    setTimeout(showToast, STATUS_API_TIMEOUT);
  }
};

export const commercialPurposeMapping = {
  Insetting: 1,
  Offsetting: 2,
  None: 3,
};
